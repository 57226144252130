<template>
    <div class="supporters-section__container">
        <div class="supporters-section__title">
            <h2 class="text-center my-3">Supporters</h2>
        </div>
        <div class="supporters-section__content justify-center">
            <div class="supporters-section__element mx-8" v-for="supporter,i in supporters" :key="i">
                <!-- <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&auto=format&fit=crop&w=989&q=80"
                    alt="image"> -->
                <img :src="supporter.logo" :alt="supporter.name" :class="supporter.class">
                </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SupportersSection',
    data() {
        return {
            supporters: [
                {
                    name: 'WiFi Netcom',
                    logo: 'assets/images/wifinetcom.png',
                    class: '',
                },
                {
                    name: 'Imballaggi Lubelli',
                    logo: 'assets/images/lubelli.png',
                    class: '',
                },
                {
                    name: 'Schiavano',
                    logo: 'assets/images/schiavano.png',
                    class: '',
                },
                {
                    name: 'Mastek',
                    logo: 'assets/images/mastek.png',
                    class: '',
                },
                {
                    name: 'BrandLabel',
                    logo: 'assets/images/brandlabel.png',
                    class: '',
                },
                {
                    name: 'Banca Popolare Pugliere',
                    logo: 'assets/images/bpp.png',
                    class: '',
                },
                {
                    name: 'Elka',
                    logo: 'assets/images/elka.png',
                    class: 'h-20',
                },
                {
                    name: 'SV71',
                    logo: 'assets/images/sv71.png',
                    class: 'h-20',
                },
                {
                    name: 'TheGINLabs',
                    logo: 'assets/images/tgl.png',
                    class: '',
                },
                {
                    name: 'Dettalgros',
                    logo: 'assets/images/detalgros.png',
                    class: 'h-20',
                },
                {
                    name: 'Alpak',
                    logo: 'assets/images/alpak.png',
                    class: 'h-24',
                },
                {
                    name: 'Optica Seclì',
                    logo: 'assets/images/secli.png',
                    class: '',
                },
                {
                    name: 'Diffusione Stereo',
                    logo: 'assets/images/diffusione.png',
                    class: '',
                },
                {
                    name: 'Giardini in Fiore',
                    logo: 'assets/images/gflowers.jpg',
                    class: '',
                },
                {
                    name: 'Vizzagri',
                    logo: 'assets/images/vizzagri.png',
                    class: '',
                },
                {
                    name: 'Percorsi d\'Arte',
                    logo: 'assets/images/percorsi.png',
                    class: 'h-12',
                },
                {
                    name: 'Libreria Dante Alighieri',
                    logo: 'assets/images/dante.jpg',
                    class: 'h-12',
                },
                {
                    name: 'Slowbar',
                    logo: 'assets/images/slowbar.png',
                    class: 'h-12',
                },
            ],
        };
    },
};
</script>
