<template>
    <div class="fichi-banner__container">
        <div class="fichi-banner__main-content">
            <div class="fichi-banner__image">
                <img src="assets/images/fichi-banner.jpeg" alt="">
            </div>
            <div class="fichi-banner__content">
                <h2>
                    Chiacchierate (in)utili per veri FICHI!
                </h2>
                <p class="text-3xl">
                    Ascolta il nostro podcast per restare aggiornato sulle novità in materia di tech e business, ma anche per bere una birra in compagnia. <br/>Amici, Birra, Tecnologia, Birra, Finanza, Business e tanta altra birra!
Condividi gli episodi, taggaci e facci sapere cosa ne pensi!
                </p>
                <div class="fichi-banner__cta">
                    <a href="https://open.spotify.com/user/31kux2nlscen6hb7ohv5gulcr77y" class="btn-big" target="_blank">Spotify</a>
                    <a href="https://youtube.com/playlist?list=PLeRXxo2wFF8P7NFzlslhQSETcSjUwbRYQ" target="_blank" class="btn-big">YouTube</a>
                </div>
            </div>
        </div>
    </div>
</template>
