import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/pages/LandingPage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/eventi/percorso-futura',
    name: 'percorso-futura',
    component: () => import('../views/pages/PercorsoFutura.vue'),
  },
  {
    path: '/eventi/futura-3/',
    name: 'futura-3',
    component: () => import('../views/pages/FuturaTre.vue'),
  },
  {
    path: '/photo-contest/start',
    name: 'photo-contest-start',
    component: () => import('../views/pages/PhotoContest.vue'),
  },
  {
    path: '/photo-contest/vote',
    name: 'photo-contest-vote',
    component: () => import('../views/pages/PhotoContestVote.vue'),
  },
  {
    path: '/photo-contest/vote/voting',
    name: 'photo-contest-voting',
    component: () => import('../views/pages/PhotoContestVoting.vue'),
  },
  {
    path: '/photo-contest/vote/completed',
    name: 'photo-contest-completed',
    component: () => import('../views/pages/PhotoContestVoteCompleted.vue'),
  },
  {
    path: '/photo-contest/chart',
    name: 'photo-contest-chart',
    component: () => import('../views/pages/PhotoContestChart.vue'),
  },
  { path: '/:pathMatch(.*)*', component: () => import('../views/pages/NotFoundPage.vue') },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log('scrollBehavior', to, from, savedPosition);
    if (savedPosition) {
      return { top: 0, left: 0, behavior: 'instant' };
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' };
    }
    return { top: 0, left: 0 };
  },
});

export default router;
