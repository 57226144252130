import { createStore } from 'vuex';

export default createStore({
  state: {
    loader_active: false,
  },
  getters: {
    loader_isLoading: (state) => state.loader_active,
  },
  mutations: {
    loader_setLoading(state, payload) {
      if (state.loader_active !== payload) {
        state.loader_active = payload;
      }
    },
  },
  actions: {
  },
  modules: {
  },
});
