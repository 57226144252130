<template>
    <div class="appointment-calendar__container">
        <div class="appointment-calendar__title">
            <div class="appointment-calendar__title__year">
                <h1>2024</h1>
            </div>
            <div class="appointment-calendar__title__text">
                <h2>Calendario <br/> Appuntamennti</h2>
            </div>
        </div>
        <div class="appointment-calendar__content">
            <div class="appointment-calendar__element" v-for="event,i in events" :key="i">
                <div class="appointment-calendar__element__date">
                    <h2 class="element__date__day">{{ event.day }}</h2>
                    <h3 class="element__date__month">{{ event.month }}</h3>
                </div>
                <div class="appointment-calendar__element__text">
                    <p class="element__name">{{ event.name }}</p>
                    <p class="element__place-time">{{ event.place }}</p>
                    <!-- <p class="element__details">{{ event.details }}</p> -->
                    <router-link v-if="event.link" class="text-primary fond-bold" :to="event.link">Dettagli ></router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppointmentCalendar',
    data() {
        return {
            events: [
                {
                    month: 'Ago',
                    day: '31',
                    name: 'Futura 3',
                    place: 'P.zza D\'Elia, Casarano',
                    // details: 'Descrizione evento 1',
                    link: '/eventi/futura-3',
                },
                {
                    month: 'Ago/23',
                    day: '23',
                    name: 'Percorso Futura',
                    place: 'P.zza D\'Elia, Casarano',
                    // details: 'Descrizione evento 1',
                    link: '/eventi/percorso-futura',
                },
                {
                    month: 'Apr/23',
                    day: '08',
                    name: 'Caccia alle Uova',
                    place: 'Parco Lineare, Casarano',
                },
                {
                    month: 'Dic/22',
                    day: '24',
                    name: 'Regalo Sospeso',
                    place: 'Serve del Cenacolo ONLUS, Casarano',
                },
                {
                    month: 'Sett/22',
                    day: '04',
                    name: 'Ripartenza Futura',
                    place: 'P.zza D\' Elia, Casarano',
                },
                {
                    month: 'Sett/22',
                    day: '03',
                    name: 'Phoenix: Albero della Rinascita',
                    place: 'Parco Lineare, Casarano',
                },

                // {
                //     month: 'Ago',
                //     day: '02',
                //     name: 'Evento 2',
                //     details: 'Descrizione evento 2',
                // },
                // {
                //     month: 'Ago',
                //     day: '03',
                //     name: 'Evento 3',
                //     details: 'Descrizione evento 3',
                // },
                // {
                //     month: 'Ago',
                //     day: '01',
                //     name: 'Evento 1',
                //     details: 'Descrizione evento 1',
                // },
                // {
                //     month: 'Ago',
                //     day: '02',
                //     name: 'Evento 2',
                //     details: 'Descrizione evento 2',
                // },
                // {
                //     month: 'Ago',
                //     day: '03',
                //     name: 'Evento 3',
                //     details: 'Descrizione evento 3',
                // },
            ],
        };
    },
};
</script>
