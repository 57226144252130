<template>
    <div class="footer">
        <div clasS="footer__content">
            <div class="footer__logo">
                <img src="/assets/images/logo_small_w.png" alt="">
                <div class="footer__logo__text">
                    <div class="social__icons">
                        <div class="social__icons__element">
                            <a href="https://www.facebook.com/futura.casarano/" target="__blank">
                                <img src="/assets/icons/facebook.svg" alt="futura-facebook">
                            </a>
                        </div>
                        <div class="social__icons__element">
                            <a href="https://www.instagram.com/futura.casarano/" target="__blank">
                                <img src="/assets/icons/instagram.svg" alt="futura-instagram">
                            </a>
                        </div>
                        <div class="social__icons__element">
                            <a href="https://youtube.com/@FuturaCasarano" target="__blank">
                                <img src="/assets/icons/youtube.svg" alt="futura-youtube">
                            </a>
                        </div>
                        <div class="social__icons__element">
                            <a href="https://open.spotify.com/user/31kux2nlscen6hb7ohv5gulcr77y" target="__blank">
                                <img src="/assets/icons/spotify.svg" alt="futura-spotify">
                            </a>
                        </div>
                    </div>
                    <h5 class="text-left text-sm font-bold">Associazione Futura</h5>
                    <p class="text-xs">
                        C.F.: 90056740757 <br/>
                        Via Da Bormida, 73042 Casarano (LE)
                    </p>
                </div>
            </div>
            <div class="footer__links">
                <h5>Sito Web</h5>
                <ul>
                    <li><router-link to="/#mission">Mission</router-link></li>
                    <li><router-link to="/#eventi">Eventi</router-link></li>
                    <!-- <li><router-link class="footer__logo" to="/contact"><img src="assets/images/logo_small.png"
                                alt=""></router-link></li> -->
                    <li><router-link to="/#suppoorters">Supporters</router-link></li>
                </ul>
            </div>
            <div class="footer__links">
                <h5>Progetti & Eventi</h5>
                <ul>
                    <li><router-link to="/eventi/percorso-futura">Percorso Futura</router-link></li>
                    <li><router-link to="/">Fichi! - Podcast</router-link></li>
                    <li><router-link to="/">Phoenix: Albero della Rinascita</router-link></li>
                </ul>
            </div>
            <div class="footer__links">
                <h5>Contatti</h5>
                <ul>
                    <li><b>Tel. </b> &nbsp; +39 393 8276092</li>
                    <li><b>E-mail:</b> &nbsp; futura.casarano@gmail.com</li>
                </ul>
                <a href="https://paypal.me/AssociazioneFutura?country.x=IT&locale.x=it_IT" target="__blank" class="btn btn-light my-2 rounded-full bg-white border-none text-[#0070BA] font-semibold flex flex-row items-center">
                    <img src="/assets/icons/paypal.svg" class="max-h-6 text-xs lg:max-h-8 ml-1 mr-2" alt="dona con paypal"/> Sostienici con una donazione!
                </a>
            </div>
        </div>
        <div class="footer__copy">
            <p>© 2023 Associazione Futura <span class="hidden lg:inline">|</span> <br class="lg:hidden" /> <b>Designed & Crafted by</b> <a href="https://www.jacopogrecuccio.it" class="underilne" target="__blank">Ing. Jacopo Grecuccio</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    components: {
    },
};
</script>
