<template>
    <div class="area-container">
        <div class="area-container__title">
            <h2 class="muted-text">Aree Tematiche</h2>
        </div>
        <div class="section area-container__cards">
            <div class="area-card">
                <div class="area-card__img">
                    <img src="assets/images/futura-rocket.png" alt="">
                </div>
                <div class="area-card__title">
                    <h3>Tech & Business</h3>
                </div>
            </div>
            <div class="area-card">
                <div class="area-card__img">
                    <img src="assets/images/futura-colors.png" alt="">
                </div>
                <div class="area-card__title">
                    <h3>Arte & Cultura</h3>
                </div>
            </div>
            <div class="area-card">
                <div class="area-card__img">
                    <img src="assets/images/futura-tree.png" alt="">
                </div>
                <div class="area-card__title">
                    <h3>Ambiente & Salute</h3>
                </div>
            </div>
            <div class="area-card">
                <div class="area-card__img">
                    <img src="assets/images/futura-community.png" alt="">
                </div>
                <div class="area-card__title">
                    <h3>Comunità & Territorio</h3>
                </div>
            </div>
        </div>
    </div>
</template>
