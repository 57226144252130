<template>
    <MainnLayout>
      <HeroLanding></HeroLanding>
      <HetoAreas id="aree-tematiche"></HetoAreas>
      <!-- <HeroMission></HeroMission> -->
      <MissionSection id="mission"></MissionSection>
      <AppointmentCalendar id="eventi"></AppointmentCalendar>
      <LandingGallery></LandingGallery>
      <FichiBanner></FichiBanner>
      <SupportersSection id="supporters"></SupportersSection>
    </MainnLayout>
</template>

<script>
import MainnLayout from '@/views/layouts/MainnLayout.vue';
import HetoAreas from '@/components/Landing/HetoAreas.vue';
import FichiBanner from '@/components/Landing/FichiBanner.vue';
import AppointmentCalendar from '@/components/Landing/AppointmentCalendar.vue';
import LandingGallery from '@/components/Landing/LandingGallery.vue';
import SupportersSection from '@/components/Landing/SupportersSection.vue';
import MissionSection from '@/components/Landing/MissionSection.vue';
import HeroLanding from '../../components/Landing/HeroLanding.vue';
// import HeroMission from '../../components/Landing/HeroMission.vue';

export default {
  name: 'LandingPage',
  components: {
    MainnLayout,
    HeroLanding,
    // HeroMission,
    HetoAreas,
    FichiBanner,
    AppointmentCalendar,
    SupportersSection,
    MissionSection,
    LandingGallery,
  },
  metaInfo() {
    return {
      title: 'Futura | Homepage',
    };
  },
};
</script>
