<template>
    <div class="hero-landing">
        <div class="hero-landing__content">
            <div class="hero-landing__content__hero">
                <h1 class="text-left">Cosa ti aspetti dal domani? </h1>
                <h4 class="text-left">31 Agosto 2024 - P.zza D'Elia (Casarano)</h4>
                <!-- <p class="h5 lg:text-2xl">
                    Per il secondo anno di fila rinnoviamo il l'appuntamento per la nostra grande festa <br/>
                </p> -->
                <p class="h5 lg:text-xl my-4">
                    Parole che Cesare Cremonini canta in uno dei suoi brani più celebri, descrivendo e raccontando il
                    forte sentimento di speranza e la fiducia nel poter rivivere un amore ormai finito.<br/>
                    E tu cosa ti aspetti dal domani? In cosa riponi la tua speranza e la tua fiducia?<br/>
                    Che sia un augurio, un sogno o un desiderio ... <b>raccontacelo attraverso la tua creatività!</b>
                                    </p>
                <div class="hero-landing__cta">
                    <!-- <router-link to="/eventi/percorso-futura" class="btn-big btn-primary mb-9">Dettagli sull'evento</router-link> -->
                     <a href="/regolamento_2024_contest.pdf" target="_blank" class="btn-big btn-primary mb-9">Scarica il Regolamento</a>
                </div>
            </div>
        </div>
        <div class="hero-landing__image">
            <img src="assets/images/loca_futura_3.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {
  name: 'HeroLanding',
  components: {
  },
};
</script>
