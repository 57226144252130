<template>
    <div class="gallery-section__container">
        <div class="gallery-section__content">
            <div class="gallery-section__gallery">
                <div class="gallery-section__gallery_element" v-for="image,i in images" :key="i" :class="image.class">
                    <img class="" :src="image.path"
                        :alt="image.alt">
                </div>
                <!-- <div class="gallery-section__gallery_element">
                    <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=989&q=80"
                        alt="image">
                </div>
                <div class="gallery-section__gallery_element">
                    <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=989&q=80"
                        alt="image">
                </div>
                <div class="gallery-section__gallery_element">
                    <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=989&q=80"
                        alt="image">
                </div>
                <div class="gallery-section__gallery_element">
                    <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=989&q=80"
                        alt="image">
                </div>
                <div class="gallery-section__gallery_element">
                    <img src="https://images.unsplash.com/photo-1523275335684-37898b6baf30?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=989&q=80"
                        alt="image">
                </div> -->
            </div>
        </div>
        <div class="gallery-section__title">
            <h2 class="muted-text text-8xl text-white text-right">Cheese!</h2>
            <h2 class="title">Scatti & <br/>Sorrisi</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LandingGallery',
    data() {
        return {
            images: [
                {
                    path: '/assets/images/photo169269892b.jpeg',
                    alt: 'futura-1',
                    class: '',
                },
                {
                    path: '/assets/images/photo1692698920d.jpeg',
                    alt: 'futura-2',
                    class: '',
                },
                {
                    path: '/assets/images/photo1692698920e.jpeg',
                    alt: 'futura-3',
                    class: '',
                },
                {
                    path: '/assets/images/photo1692698920.jpeg',
                    alt: 'futura-4',
                    class: 'col-span-2',
                },
                {
                    path: '/assets/images/photo1692698920a.jpeg',
                    alt: 'futura-5',
                    class: '',
                },
                {
                    path: '/assets/images/photo1692717960b.jpeg',
                    alt: 'futura-6',
                    class: 'col-span-3',
                },
                {
                    path: '/assets/images/photo1692717960a.jpeg',
                    alt: 'futura-7',
                    class: '',
                },
                {
                    path: '/assets/images/photo1692717960d.jpeg',
                    alt: 'futura-8',
                    class: 'col-span-2',
                },
            ],
        };
    },
};
</script>
