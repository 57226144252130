<template>
      <Navbar/>
      <div class="w-100 mx-auto">
          <div class="overflow-x-hidden">
              <slot></slot>
          </div>
      </div>
      <Footer/>
</template>

<script>
import Navbar from '@/components/common/Navbar.vue';
import Footer from '@/components/common/Footer.vue';

export default {
  name: 'MainLayout',
  components: {
    Navbar,
    Footer,
  },
};
</script>
