<template>
    <div class="section__container">
        <div class="section__content">
            <div class="w-5/6 mx-auto lg:mx-0 lg:w-1/2 px-8 ">
                <h2 class="my-7">La nostra mission</h2>
                <p class="my-7 text-xl">
                    Hai mai visto un futuro senza un presente? Ecco, <b>Futura</b> nasce per cambiare la rassegnazione dell’idea che si ha di futuro mediante l’azione nel presente. <i>“Dire, senza fare… che senso ha?”</i>.
                    <br/>
                    È un’idea nata per essere in <b>continuo divenire</b>, quello che ci proponiamo è di <b>seminare idee</b> per raccogliere progetti grazie alla passione, alla disponibilità e la perseveranza degli amici che credono fortemente in questo bellissimo cammino associativo.
                    <br/>
                    <br/>
                    Nel nostro percorso associativo ci proponiamo di affrontare iniziative ed eventi inerenti a diverse tematiche culturali, sociali e ambientali nell’intento di valorizzare e promuovere la città mediante la propria attiva partecipazione.
                    Leva fondamentale per affrontare le sfide presenti e future.
                    <br/>
                    <br/>
                    La nostra organizzazione prevede quattro team inerenti 4 macroaree:
                </p>
                <ul class="text-lg pl-4">
                    <li>
                        <b>Tech & Business</b>: si propone di diffondere e promuovere la digitalizzazione e l’innovazione tecnologica
                    </li>
                    <li>
                        <b>Ambiente & Salute</b>: che propone di contribuire alla tutela e al miglioramento delle nostre ricchezze paesaggistiche
                    </li>
                    <li>
                        <b>Comunità & Territorio</b>: si occuperà di promuovere e sensibilizzare tematiche sociali
                    </li>
                    <li>
                        <b>Arte & Cultura</b>: punta a scoprire e valorizzare i talenti del nostro paese
                    </li>
                </ul>
            </div>
            <div class="w-1/2 text-right">
                <img class="max-h-[45rem]" src="/assets/images/photo169269892b.jpeg" alt="La nostra mission">
            </div>
        </div>
    </div>
</template>
