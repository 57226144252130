<template>
    <div class="navbar">
        <div class="navbar__links">
            <ul>
                <li><router-link class="navbar__logo" to="/"><img src="/assets/images/logo_small.png" alt=""></router-link>
                </li>
                <li><router-link to="/#mission">Mission</router-link></li>
                <li><router-link to="/#aree-tematiche">Aree Tematiche</router-link></li>
                <li><router-link to="/#eventi">Eventi</router-link></li>
                <!-- <li><router-link to="/contact">News</router-link></li> -->
                <li><router-link to="/#supporters">Supporters</router-link></li>
                <!-- <li><router-link to="/contact">Contaatti</router-link></li> -->
            </ul>
        </div>
        <div class="navbar-mobile lg:hidden">
            <div class="navbar-mobile__logo">
                <router-link class="" to="/"><img src="/assets/images/logo_small.png" alt=""></router-link>
            </div>
            <div class="navbar-mobile__menu">
                <div class="navbar-mobile__menu__burger" @click="toggleMobileMenu()">
                    <div class="navbar-mobile__menu__burger__line"></div>
                    <div class="navbar-mobile__menu__burger__line"></div>
                    <div class="navbar-mobile__menu__burger__line"></div>
                </div>
                <div class="navbar-mobile__menu__links" :class="{'navbar-mobile__menu__links__active': this.isMobileMenuOpen === true}">
                    <ul>
                        <li><router-link to="/#mission">Mission</router-link></li>
                        <li><router-link to="/#aree-tematiche">Aree Tematiche</router-link></li>
                        <li><router-link to="/#eventi">Eventi</router-link></li>
                        <!-- <li><router-link to="/contact">News</router-link></li> -->
                        <li><router-link to="/#supporters">Supporters</router-link></li>
                        <!-- <li><router-link to="/contact">Contaatti</router-link></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    components: {
    },
    data() {
        return {
            isMobileMenuOpen: false,
        };
    },
    methods: {
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
    },
};
</script>
